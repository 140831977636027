<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "ValidationRules.NewValidationRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-validation-rule-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "ValidationRules.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="validationRuleData.name"
          :spellcheck="this.$isTextSpellCheck"
          :autocomplete="this.$isAutoComplete"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="validationType" class="form-label required">{{
          $t(
            "ValidationRules.ValidationType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedValidationType"
          :allowEmpty="false"
          :resetSelect="validationTypeSelectReset"
          :data="validationTypeData"
          @onChange="onChangeForValidationType"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Formula" class="form-label required"
          >{{
            $t(
              "ValidationRules.Formula",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <a
            @click="openFormulaEditor"
            class="cursor-pointer text-decoration-none text-primary ms-1"
          >
            <span
              ><img
                width="20"
                height="20"
                :src="
                  require('@/assets/images/icons/function-primary.png')
                " /></span
            >&nbsp;
            {{
              $t(
                "Components.FormulaEditorModal.OpenFormulaEditor",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</a
          ></label
        >
        <textarea
          name="Formula"
          class="form-control"
          id="Formula"
          :spellcheck="this.$isTextSpellCheck"
          v-model="validationRuleData.formula"
          rows="5"
          required
        ></textarea>
        <div class="alert alert-info p-1 mt-0 mb-0">
          {{
            $t(
              "ValidationRules.FormulaInformation",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="ErrorText" class="form-label required"
          >{{
            $t(
              "ValidationRules.ErrorText",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <textarea
          name="ErrorText"
          class="form-control"
          id="ErrorText"
          :spellcheck="this.$isTextSpellCheck"
          v-model="validationRuleData.errorText"
          rows="2"
          required
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="IsShowMessageInModal" class="form-label">{{
          $t(
            "ValidationRules.IsShowMessageInModal",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsShowMessageInModal"
          v-model="validationRuleData.isShowMessageInModal"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="validationRuleData.isActive"
        />
      </div>
      <div class="form-check" v-if="detailForwardOrEditForwardType">
        <label for="IsCheckOnFormula" class="form-label">{{
          $t(
            "ValidationRules.IsCheckOnFormula",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsCheckOnFormula"
          v-model="validationRuleData.isCheckOnFormula"
        />
      </div>
      <div
        class="col col-md-6 mb-3"
        v-if="
          detailForwardOrEditForwardType && !validationRuleData.isCheckOnFormula
        "
      >
        <label for="PageLayout" class="form-label required">{{
          $t(
            "ValidationRules.PageLayout",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangePageLayout"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          openDirection="top"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryPageLayoutsWithFormula?customObjectFormulaName={0}',
              this.validationRuleData.customObjectFormulaName
            )
          "
          :isDisabled="
            String.isNullOrWhiteSpace(
              this.validationRuleData.customObjectFormulaName
            )
              ? true
              : false
          "
        />
      </div>
      <div
        class="col col-md-6 mb-3"
        v-if="
          detailForwardOrEditForwardType && validationRuleData.isCheckOnFormula
        "
      >
        <label for="FieldName" class="form-label required">{{
          $t(
            "ValidationRules.FieldName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeFieldName"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          openDirection="top"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsByTypeWithFormula?parentFormulaName={0}&type=FormulaString',
              this.validationRuleData.customObjectFormulaName
            )
          "
          :isDisabled="
            String.isNullOrWhiteSpace(
              this.validationRuleData.customObjectFormulaName
            )
              ? true
              : false
          "
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
  <FormulaEditorModal
    :formulaField="formulaField"
    parentRecordPrefix=""
    @updateFormula="updateFormula"
  />
</template>
<script>
import $ from "jquery";
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
export default {
  name: "CustomObjectNewValidationRule",
  components: { FormulaEditorModal },
  data() {
    return {
      formulaField: {},
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      validationRuleData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
        validationTypeId: 1,
      },
      detailForwardOrEditForwardType: false,
      validationTypeSelectReset: false,
      isSuccess: false,
      validationTypeData: [
        {
          key: "1",
          value: this.$t(
            "ValidationRules.ValidationTypeError",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "ValidationRules.ValidationTypeWarning",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "ValidationRules.ValidationTypeInformation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "ValidationRules.ValidationTypeDetailBlock",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "ValidationRules.ValidationTypeEditBlock",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "6",
          value: this.$t(
            "ValidationRules.ValidationTypeDetailForward",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "7",
          value: this.$t(
            "ValidationRules.ValidationTypeEditForward",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "9", //Enum classında key id 8 RecordError ve bu sayfada kullanılmıyor!
          value: this.$t(
            "ValidationRules.ValidationTypeValidationForComponent",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "10",
          value: this.$t(
            "ValidationRules.ValidationTypeValidationForButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "11",
          value: this.$t(
            "ValidationRules.ValidationTypeDeleteBlock",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedValidationType: [
        {
          key: "1",
          value: this.$t(
            "ValidationRules.ValidationTypeError",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    openFormulaEditor() {
      this.formulaField = this.validationRuleData;
      $(".btn-open-formula-editor-modal").trigger("click");
    },
    updateFormula(formula) {
      this.validationRuleData.formula = formula;
    },
    onChangePageLayout: function (selected) {
      this.validationRuleData.pageLayoutFormulaName = selected.key;
    },
    onChangeFieldName: function (selected) {
      this.validationRuleData.fieldFormulaName = selected.key;
    },
    onChangeForValidationType: function (selected) {
      this.detailForwardOrEditForwardType =
        selected.key == 6 || selected.key == 7 ? true : false;
      this.validationRuleData.validationTypeId = selected.key;
    },
    onClear() {
      this.validationRuleData = {
        isActive: true,
        customObjectPublicId: this.validationRuleData.customObjectPublicId,
        isShowMessageInModal: false,
        customObjectFormulaName:
          this.validationRuleData.customObjectFormulaName,
        validationTypeId: this.validationRuleData.validationTypeId,
      };

      this.validationTypeSelectReset = !this.validationTypeSelectReset;

      $(".form-validation-rule-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-validation-rule-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ValidationNew", { ...this.validationRuleData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/ValidationRules/" +
                  this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {},
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.validationRuleData.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.validationRuleData.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
